"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMAIL_TEMPLATE_COPY = void 0;
exports.EMAIL_TEMPLATE_COPY = {
    imageUploadHelpText: "Click 'Save' to view your image in the preview. If no logo is uploaded, then the image banner won't show up.",
    imageUploadDropZoneText: 'Recommended width 800px or larger / max file size: 1MB',
    imageUploadLinkHelpText: "When a customer clicks on the image banner, they'll be redirected to this URL.",
    urlValidationErrorMsg: "Please provide a valid url that starts with 'http://' or 'https://' and includes a domain extension such as '.com'.",
    variablesTooltipText: 'Variables allow you to add dynamic text to your emails. Make sure to copy the variable name verbatim.',
    emailTemplateUpdateSuccess: 'Email template configuration successfully updated.',
    emailTemplateFormValidationErrors: 'Error: Please fix field validation errors and then try again.',
    generalServerError: 'The server could not be reached, please try again later.',
    verificationProcessingMsg: "Your account verification is being processed, this can take up to 24 hours. Once you're verified, you can send emails via Malomo. In the mean time, you can customize your email templates."
};
