"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailPreview = void 0;
const react_1 = __importStar(require("react"));
const polaris_1 = require("@shopify/polaris");
const polaris_icons_1 = require("@shopify/polaris-icons");
const AccountContext_1 = require("contexts/AccountContext");
const common_1 = require("@/emails/common");
const EmailPreview = (props) => {
    var _a, _b, _c, _d;
    const [viewMode, setViewMode] = (0, react_1.useState)('desktop');
    const iframeWidth = viewMode === 'mobile' ? '320px' : '100%';
    const { account } = (0, AccountContext_1.useAccount)();
    const params = {
        notificationTypeSlug: props.notificationTypeSlug,
        config: props.config,
        template: props.template,
        account: {
            id: account === null || account === void 0 ? void 0 : account.id
        }
    };
    const iframeUrl = (0, common_1.useDebouncedIframeUrl)(params);
    const fromEmail = `noreply@${account === null || account === void 0 ? void 0 : account.primaryHostedDomain.token}`;
    return (react_1.default.createElement(polaris_1.Card, null,
        react_1.default.createElement(polaris_1.BlockStack, { gap: '400' },
            react_1.default.createElement(polaris_1.BlockStack, { inlineAlign: 'center' },
                react_1.default.createElement(polaris_1.ButtonGroup, { variant: 'segmented' },
                    react_1.default.createElement(polaris_1.Button, { icon: polaris_icons_1.DesktopIcon, onClick: () => setViewMode('desktop'), pressed: viewMode === 'desktop' }, "Desktop"),
                    react_1.default.createElement(polaris_1.Button, { icon: polaris_icons_1.MobileIcon, onClick: () => setViewMode('mobile'), pressed: viewMode === 'mobile' }, "Mobile"))),
            react_1.default.createElement(polaris_1.Divider, null),
            react_1.default.createElement(polaris_1.BlockStack, { gap: '150' },
                ((_a = props.template) === null || _a === void 0 ? void 0 : _a.subject) && (react_1.default.createElement(polaris_1.InlineStack, { gap: '200' },
                    react_1.default.createElement(polaris_1.Text, { as: 'span', tone: 'subdued', variant: 'bodyMd' }, "subject:"),
                    react_1.default.createElement(polaris_1.Text, { as: 'span', fontWeight: 'bold', variant: 'bodyMd' }, (_b = props.template) === null || _b === void 0 ? void 0 : _b.subject))),
                react_1.default.createElement(polaris_1.InlineStack, { gap: '200' },
                    react_1.default.createElement(polaris_1.Text, { as: 'span', tone: 'subdued', variant: 'bodyMd' }, "from:"),
                    react_1.default.createElement(polaris_1.InlineStack, { gap: '050' },
                        react_1.default.createElement(polaris_1.Text, { as: 'span', fontWeight: 'bold', variant: 'bodyMd' }, ((_c = props.config) === null || _c === void 0 ? void 0 : _c.senderName) || (account === null || account === void 0 ? void 0 : account.name)),
                        react_1.default.createElement(polaris_1.Text, { as: 'span', tone: 'subdued', variant: 'bodyMd' },
                            "<",
                            fromEmail,
                            ">"))),
                react_1.default.createElement(polaris_1.InlineStack, { gap: '200' },
                    react_1.default.createElement(polaris_1.Text, { as: 'span', tone: 'subdued', variant: 'bodyMd' }, "reply-to:"),
                    react_1.default.createElement(polaris_1.InlineStack, { gap: '100' },
                        react_1.default.createElement(polaris_1.Text, { as: 'span', variant: 'bodyMd' }, ((_d = props.config) === null || _d === void 0 ? void 0 : _d.replyToEmail) || fromEmail)))),
            react_1.default.createElement(polaris_1.Divider, null),
            react_1.default.createElement(polaris_1.BlockStack, { inlineAlign: 'center' },
                react_1.default.createElement("iframe", { src: iframeUrl, style: { height: '80vh' }, width: iframeWidth })))));
};
exports.EmailPreview = EmailPreview;
